import Layout from "../components/layout";
import { Ovocard, LeagueCard, CupPoolCard, Picture } from "../components/cards";
import "../styles/explore.css";
import "../index.css";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setHistory } from "../redux/slices/generalSlice";
import { Loading } from "../App";
import Tournaments from "./tournaments";
import { useMediaQuery } from "react-responsive";
import { NoGames } from "../components/NoGames";
import { useTour } from "@reactour/tour";
import {
  setCurrentTour,
  setAllowButtonClick,
  setTourMode,
} from "../redux/slices/tourSlice";
import steps, { desktopSteps } from "../components/tour/navigationTour";
// import { newGameSteps, new1v1Steps } from "../components/steps";
import mainSteps from "../components/tour/mainTour";
import Joyride, {
  ACTIONS,
  EVENTS,
  ORIGIN,
  STATUS,
  CallBackProps,
} from "react-joyride";

export default function Explore(props) {
  const allGames = useSelector((state) => state.general.allGames);
  const available_1v1 = useSelector((state) => state.general.explore);
  const leagues = useSelector((state) => state.general.leagues);
  const tourMode = useSelector((state) => state.tour.tourMode);
  const [active_mode, setActiveMode] = useState("All");

  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const tournamentLoading = useSelector(
    (state) => state.general.tournamentLoading
  );
  const { showInfo, showTutorial } = props;
  const tutorialRef = useRef(null);
  const { setIsOpen } = useTour();
  const dispatch = useDispatch();

  useEffect(() => {}, [tourMode]);

  const [activeTutorial, setActiveTutorial] = useState(0);
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const [size, setSize] = useState("400");

  let games;
  if (active_mode === "All") {
    games = allGames;
  } else if (active_mode === "1v1") {
    games = available_1v1;
  } else {
    games = leagues;
  }

  useEffect(() => {}, [allGames]);

  let display = "none";
  if (show) {
    display = "block";
  } else {
    display = "none";
  }

  useEffect(() => {
    // if(showTutorial && tutorialRef.current){
    //     tutorialRef.current.click();
    // }
  }, [showInfo]);

  useEffect(() => {
    if (showTutorial && tutorialRef.current) {
      tutorialRef.current.click();
    }
  }, [tutorialRef.current, showTutorial]);

  useEffect(() => {
    if (isMobile) {
      setSize("300");
    } else {
      setSize("400");
      setShow(false);
    }
  }, [isMobile]);

  const [run, setRun] = useState(false);
  const [mainRun, setMainRun] = useState(false);
  const [stepIndex, setStepIndex] = useState(0);
  const [diableOverlay, setdiableOverlay] = useState(true);

  const currentTour = useSelector((state) => state.tour.currentTour);

  const handleJoyrideCallback = (data) => {
    const { action, index, origin, status, type } = data;
    // console.log(`intro tour action ${action} ${status}`);

    
    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      // Update state to advance the tour
      setStepIndex(index + (action === ACTIONS.PREV ? -1 : 1));
    } else if ([STATUS.FINISHED].includes(status)) {
      dispatch(setCurrentTour("gameTypeModal"));
    } else if ([STATUS.SKIPPED].includes(status)) {
      dispatch(setCurrentTour(""));
      
    }

  };

  const handleMainJoyrideCallback = (data) => {
    const { action, index, origin, status, type } = data;
    // console.log(`main tour action ${action}`);

    if (action == "close") {
    } else if (action == "next") {
      dispatch(setCurrentTour("intro"));
      
      setRun(false);
      setMainRun(true);
    }
    
    if (action == ACTIONS.CLOSE) {
      dispatch(setCurrentTour(""));
      
    }

    //   if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
    //     // Update state to advance the tour
    //     dispatch(setCurrentTour('intro'));
    //   setStepIndex(index + (action === ACTIONS.PREV ? -1 : 1));
    // } else if ([STATUS.FINISHED].includes(status)) {
    //   dispatch(setCurrentTour('intro'));

    //   // You need to set our running state to false, so we can restart if we click start again.
    //   // setRun(false);
    // } else if ([STATUS.SKIPPED].includes(status)) {
    //   dispatch(setCurrentTour('intro'));

    // }

    // console.groupCollapsed(type);
    //eslint-disable-line no-console
    // console.groupEnd();
  };

  useEffect(() => {
    // console.log(`current tour is ${currentTour}`);

    if (currentTour != "intro") {
      setRun(false);
    } else if (currentTour == "intro") {
      setRun(true);
    }

    if (currentTour == "main") {
      setMainRun(true);
      if (!localStorage.getItem("tour")) {
      }
    } else if (currentTour != "main") {
      setMainRun(false);
    }
  }, [currentTour]);

  useEffect(() => {
    if (currentTour == "intro") {
      setRun(true);

      if (stepIndex == 6) {
        setdiableOverlay(false);
        dispatch(setAllowButtonClick("newGame"));
      } else {
        dispatch(setAllowButtonClick("none"));
      }
    }
  }, [stepIndex]);

  const closeTut = (tut) => {
    let tutorial = tut ? tut : "none";
    dispatch(setCurrentTour(tutorial));
  };

  const resetTutorial = () => {
    localStorage.setItem("sequence", "[]");
    localStorage.removeItem("tour");
    dispatch(setCurrentTour("intro"));
    dispatch(setTourMode(true));
    navigate("/explore");
  };

  return (
    <Layout>
      {/* setps */}
      <Joyride
        steps={isMobile ? steps : desktopSteps}
        showProgress={true}
        run={run && tourMode}
        autoStart={false}
        continuous={true}
        showSkipButton={true}
        allowClicksThruHole={true}
        disableOverlay={false}
        disableOverlayClose={true}
        callback={handleJoyrideCallback}
        hideCloseButton={true}
        spotlightClicks={true}
        locale={{ next: "Next step" }}
      ></Joyride>

      {/* intro */}
      <Joyride
        steps={mainSteps}
        run={mainRun && tourMode}
        autoStart={false}
        continuous={true}
        showSkipButton={true}
        allowClicksThruHole={true}
        disableOverlay={false}
        disableOverlayClose={true}
        callback={handleMainJoyrideCallback}
        hideCloseButton={false}
        spotlightClicks={true}
        locale={{ last: "Start the tour" }}
        buttons={{
          next: {
            style: {
              backgroundColor: "#4CAF50",
              color: "white",
              border: "none",
              padding: "10px 20px",
              fontSize: "16px",
            },
          },
        }}
      ></Joyride>

      <div className="explore-cont">
        <div className="col-12 col-lg-8 explore-parent-div h-100">
          <div className=" page-header-text">
            <h4>
              <b>Explore</b>
            </h4>
            <div className="dropdown">
              <button
                className="btn  dropdown-toggle p-0"
                type="button"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="30"
                  fill="currentColor"
                  className="bi bi-info-circle-fill"
                  viewBox="0 0 16 16"
                  // onClick={isMobile ? () => setShow(!show) : null}
                  // {...(!isMobile && {
                  //   "data-bs-toggle": "modal",
                  //   "data-bs-target": "#TutorialModal",
                  // })}
                >
                  <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16m.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2" />
                </svg>
              </button>

              <ul
                className="dropdown-menu"
                aria-labelledby="dropdownMenuButton1"
              >
                <li>
                  <a
                    className="dropdown-item sans d-lg-none"
                    onClick={() => navigate("/leaderboards")}
                    href="#"
                  >
                    Leaderboards
                  </a>
                </li>
                <li>
                  <hr className="dropdown-divider d-lg-none" />{" "}
                </li>
                <li>
                  <a
                    className="dropdown-item sans"
                    href="#"
                    onClick={resetTutorial}
                  >
                    Versuz Tour
                  </a>
                </li>
                {/* <li>
                  <hr className="dropdown-divider" />{" "}
                </li>
                <li>
                  <a className="dropdown-item sans" href="#">
                    How to play 1v1
                  </a>
                </li>
                <li>
                  <a className="dropdown-item sans" href="#">
                    How to play Streaks 🔥
                  </a>
                </li>
                <li>
                  <a className="dropdown-item sans" href="#">
                    How to play League
                  </a>
                </li>
                <li>
                  <a className="dropdown-item sans disabled" href="#">
                    How to play Pool
                  </a>
                </li>
                <li>
                  <a className="dropdown-item sans disabled" href="#">
                    Versuz Ranks
                  </a>
                </li> */}
              </ul>
            </div>
          </div>

          <div className="d-md-none">{/* <CupCarousel /> */}</div>
          {/* <div className="d-none d-md-flex col-12 between-row">
            <div className="col-5">
              <Cup name="euro" joinable={true} />
            </div>
            <div className="col-5">
              <Cup name="copa" joinable={true} />
            </div>
          </div> */}
          {allGames?.length > 0 && (
            <GameModePills
              active_mode={active_mode}
              setActiveMode={setActiveMode}
            />
          )}
          <GamesDiv
            games={games ? games : []}
            showPrivate={false}
            active_mode={active_mode}
            explore={true}
          />
        </div>
        <div className="leaderboard">
          {tournamentLoading ? (
            <div className="justify-center" style={{ height: "70dvh" }}>
              <div className="spinner-grow" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          ) : (
            <Tournaments />
          )}
        </div>
      </div>
    </Layout>
  );
}

export function GameModePills(props) {
  const { active_mode, setActiveMode } = props;
  let modes = ["All", "1v1", "Leagues"];

  return (
    <div className="game-mode-pills sans">
      {modes.map((mode, i) => {
        return (
          <div
            className={`border col-md-3 me-1 me-md-5 text-center cursor rounded-pill game_mode_pills ${
              active_mode === mode && "active_mode_pill"
            }`}
            style={{}}
            onClick={() => setActiveMode(mode)}
            key={i}
          >
            {mode}
          </div>
        );
      })}
    </div>
  );
}

export function GamesDiv(props) {
  const {
    mh = "70dvh",
    games,
    showPrivate,
    active_mode,
    showNav = false,
    cuppools = [],
    explore,
    history,
  } = props;
  const history_data = useSelector((state) =>
    state.general.history !== null ? state.general.history : []
  );
  const [historyLoad, setHistoryLoad] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  function modifyUrl(originalUrl) {
    const url = new URL(originalUrl);
    if (!url.pathname.includes("/history")) {
      url.pathname = "history";
    }
    return url.toString();
  }

  useEffect(() => {}, [history_data]);

  const switchPage = async (page) => {
    setHistoryLoad(true);

    try {
      const url = modifyUrl(page);
      const authToken = localStorage.getItem("vsrz")
        ? `Token ${JSON.parse(localStorage.getItem("vsrz")).token}`
        : "Token ";

      const config = {
        method: "GET",
        headers: {
          reactkey: process.env.REACT_APP_AUTH_KEY,
          "Content-Type": "application/json",
          Authorization: authToken,
        },
      };
      const response = await fetch(url, config)
        .then((data) => data.json())
        .catch((err) => err);

      dispatch(setHistory(response));
    } catch (err) {
      navigate("/history");
    }
    setHistoryLoad(false);
  };
  let text;
  if (active_mode === "1v1" && games.length === 0) {
    text = "No 1v1 available";
  } else if (active_mode === "All" && games.length === 0) {
    text = "No game available";
  } else if (active_mode === "Leagues" && games.length === 0) {
    text = "No league available";
  }
  if (window.location.pathname === "/history") {
    text = "You do not have any concluded game";
  }

  return (
    <div
      className={`explore-div ${games.length === 0 && "justify-center"}`}
      style={{ overflowY: "auto" }}
    >
      <div
        className={`games-div ${!explore && "games"}`}
        style={{ overflowY: "auto", maxHeight: "" }}
      >
        <div
          className={`
                    ${historyLoad && "justify-content-center d-flex"} ${
            games.length > 0 && "games-div-body"
          } 
            ${
              history ? "history" : explore ? "explore" : "games"
            }  p-0 px-md-3`}
        >
          {historyLoad ? (
            <Loading />
          ) : (
            <>
              {games.length > 0 ? (
                games.map((game, i) => {
                  return (
                    <div key={i}>
                      {!game.league && !game.competition ? (
                        <Ovocard
                          key={i}
                          data={game}
                          showPrivate={showPrivate}
                        />
                      ) : (
                        <>
                          {game.league && <LeagueCard key={i} data={game} />}
                          {game.competition && (
                            <CupPoolCard key={i} data={game} />
                          )}
                        </>
                      )}
                    </div>
                  );
                })
              ) : (
                <NoGames text={text} />
              )}
            </>
          )}
        </div>
        {showNav && (
          <>
            {(history_data?.previous || history_data?.next) && (
              <nav aria-label="Page navigation" className="switch_nav">
                <ul className="pagination justify-content-between">
                  <li
                    className={`page-item ${
                      (!history_data.previous || historyLoad) && "disabled"
                    } cursor`}
                  >
                    <a
                      className="links page-link"
                      onClick={() => switchPage(history_data?.previous)}
                      aria-label="Previous"
                    >
                      <span aria-hidden="true">&laquo;</span>
                    </a>
                  </li>
                  <li
                    className={`page-item ${
                      (!history_data.next || historyLoad) && "disabled"
                    } cursor`}
                  >
                    <a
                      className="links page-link"
                      onClick={() => switchPage(history_data?.next)}
                      aria-label="Next"
                    >
                      <span aria-hidden="true">&raquo;</span>
                    </a>
                  </li>
                </ul>
              </nav>
            )}
          </>
        )}
      </div>
    </div>
  );
}
