import Regions, { ColumnHeader } from "../pages/select";
import { useNavigate, useParams } from "react-router-dom";
import premier_league_logo from "../images/premier_league_logo.webp";
import "../styles/fixtures.css";
import { Logo, LayoutHeader } from "./layout";
import { Loading, convertTo12HourFormat } from "../App";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateSelectedOptions } from "../redux/slices/ticketSlice";
import { setCurrentFixture } from "../redux/slices/selectSlice";
import { useMediaQuery } from "react-responsive";
import { Back, ViewSelections } from "../pages/select";
import { Picture } from "./cards";
import Fixture from "./fixture";
import { setTourMode } from "../redux/slices/tourSlice";
import {  matchSteps } from "../components/steps";
import { setCurrentTour, setAllowButtonClick } from "../redux/slices/tourSlice";
import Joyride, {
  ACTIONS,
  EVENTS,
  ORIGIN,
  STATUS,
  CallBackProps,
} from "react-joyride";

export default function FixtureDetails() {
  const [fixture, setFixture] = useState(null);
  const [competition, setCompetition] = useState(null);
  const { fixtureId } = useParams();
  const [loading, setLoading] = useState(false);
  const ticketSelections = useSelector((state) => state.ticket.selectedOptions);
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const [statLoading, setStatLoading] = useState(false);
  const [stats, setStats] = useState(null);
  const tourMode = useSelector((state) => state.tour.tourMode);
  const tour = useSelector((state) => state.tour.currentTour);
  const [stepIndex, setStepIndex] = useState(0);
  const [run, setRun] = useState(false);
  const dispatch = useDispatch();

  const closeTut = (tut) => {
    let tutorial = tut ? tut : "none";
    dispatch(setCurrentTour(tutorial));
  };

  const handleJoyrideCallback = (data) => {
    const { action, index, origin, status, type } = data;

    // if (action === ACTIONS.CLOSE || action == 'stop') {
    //   // do something
    //   dispatch(setTourMode(false))
    //   closeTut("")
    // }

    
  };


  useEffect(() => {
    if (tour == "match" ) {
      // if(!data.includes('match')){
        setTimeout(() => {
          setRun(true);
        }, 500);
      // }
    } else {
      setRun(false);
    }
  }, [tour]);

  let home_prevs = null;
  let away_prevs = null;
  if (stats) {
    home_prevs = stats.last_five.slice(0, 5);
    away_prevs = stats.last_five.slice(5);
  }

  const getGameData = async () => {
    setLoading(true);
    const url = `${process.env.REACT_APP_BASE_URL}/get_fixture/${fixtureId}`;

    const config = {
      method: "GET",
      headers: {
        reactkey: process.env.REACT_APP_AUTH_KEY,
        "Content-Type": "application/json",
      },
    };

    const response = await fetch(url, config)
      .then((data) => data.json())
      .catch((err) => err);

    if (response.success) {
      setFixture(response.fixture_data);
      setCompetition(response.competition);
    } else {
      window.history.back();
    }
    setLoading(false);
  };

  const getGameStats = async () => {
    setStatLoading(true);
    const url = `${process.env.REACT_APP_BASE_URL}/fixture_stats/${fixtureId}`;

    const config = {
      method: "GET",
      headers: {
        reactkey: process.env.REACT_APP_AUTH_KEY,
        "Content-Type": "application/json",
      },
    };

    const response = await fetch(url, config)
      .then((data) => data.json())
      .catch((err) => err);

    if (response.success) {
      setStats(response);
    } else {
      // window.history.back()
    }
    setStatLoading(false);
  };

  useEffect(() => {
    getGameData();
    getGameStats();
  }, []);


  let img = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="currentColor"
      className="bi bi-bar-chart-fill"
      viewBox="0 0 16 16"
      data-bs-toggle=""
      data-bs-target=""
    >
      <path d="M1 11a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1zm5-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1zm5-5a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1z" />
    </svg>
  );

  return (
    <div className="">
      {/* competition,statLoading,fixture,stats,home_prevs,away_prevs */}
      <StatsModal
        competition={competition}
        statLoading={statLoading}
        fixture={fixture}
        stats={stats}
        home_prevs={home_prevs}
        away_prevs={away_prevs}
      />

      {!isMobile ? (
        <Regions>
          {stats && <ColumnHeader name="" svg={img} />}
          {loading || !fixture ? (
            <Loading />
          ) : (
            <div className="px-3 sans">
              <div className="between-row" style={{ padding: "0px 70px" }}>
                <div className="align-col-center col-3">
                  <img
                    src={fixture.home_team_crest}
                    height={"50px"}
                    width={"50px"}
                  />
                  <div className="medium text-center">{fixture.home_team}</div>
                </div>
                <div className="small align-col-center">
                  <div className="text-center">
                    {competition.competition_name}
                  </div>
                  <Logo clickable={false} height="30" width="30" />
                  <div>{convertTo12HourFormat(fixture.date.split(" ")[1])}</div>
                </div>
                <div className="align-col-center col-3">
                  <img
                    src={fixture.away_team_crest}
                    height={"50px"}
                    width={"50px"}
                  />
                  <div className="medium text-center">{fixture.away_team}</div>
                </div>
              </div>
              <div
                className="border-top mt-3"
                style={{ height: "66dvh", overflow: "auto" }}
                data-tut={`${!isMobile && 'select-match-step-0'}`}
                
              >
                {fixture.odds.map((odd, i) => {
                  return (
                    <div className="d-flex flex-column my-2" key={i}>
                      <div className="small">{odd.name.replace(/_/g, " ")}</div>
                      <div className="between-row mt-1">
                        {odd.options.map((opt) => {
                          
                          return (
                            <OddButton
                              options={opt}
                              group={odd.name}
                              key={opt}
                              odd={odd}
                              present={
                                ticketSelections[fixture.id] &&
                                (ticketSelections[fixture.id][odd.name] === opt
                                  ? true
                                  : false)
                              }
                            />
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </Regions>
      ) : (
        <LayoutHeader>
          <div className="justify-center justify-content-between px-3 mb-2">
            <Back />
            <ViewSelections />
          </div>
          {loading || !fixture ? (
            <Loading />
          ) : (
            <div className="px-3 sans">
              {/* Joyride  matchSteps */}
              {/* my edit */}
              <Joyride
                steps={matchSteps}
                run={run && tourMode}
                autoStart={true}
                // stepIndex={stepIndex}
                continuous={true}
                showSkipButton={true}
                allowClicksThruHole={false}
                disableOverlay={false}
                disableOverlayClose={false}
                spotlightClicks={false}
                disableBodyFocus={false} // Enable scrolling
                callback={handleJoyrideCallback}
                hideCloseButton={true}
              ></Joyride>

              <div
                className="between-row fixture-detail-cont"
                style={{ padding: "0px 70px" }}
              >
                <div className="align-col-center col-3">
                  <img
                    src={fixture.home_team_crest}
                    height={"50px"}
                    width={"50px"}
                  />
                  <div className="medium text-center">{fixture.home_team}</div>
                </div>
                <div className="small align-col-center">
                  <div className="text-center">
                    {competition.competition_name}
                  </div>
                  <Logo clickable={false} height="30" width="30" />
                  <div>{convertTo12HourFormat(fixture.date.split(" ")[1])}</div>
                </div>
                <div className="align-col-center col-3">
                  <img
                    src={fixture.away_team_crest}
                    height={"50px"}
                    width={"50px"}
                  />
                  <div className="medium text-center">{fixture.away_team}</div>
                </div>
              </div>
              {stats && <ColumnHeader name="" svg={img} />}
              <div
                className="border-top mt-3"
                style={{ height: "66dvh", overflow: "auto" }}
                data-tut={`${isMobile && 'select-match-step-0'}`}
              >
                {/* Joyride -> matchsteps */}

                {fixture.odds.map((odd, i) => {
                  return (
                    <div className="d-flex flex-column my-2" key={i}>
                      <div className="small odd-name">{odd.name.replace(/_/g, " ")}</div>
                      <div className="between-row mt-1">
                        {odd.options.map((opt) => {
                          
                          return (
                            <OddButton
                              options={opt}
                              group={odd.name}
                              key={opt}
                              odd={odd}
                              present={
                                ticketSelections[fixture.id] &&
                                (ticketSelections[fixture.id][odd.name] === opt
                                  ? true
                                  : false)
                              }
                            />
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </LayoutHeader>
      )}
    </div>
  );
}

function OddButton(props) {
  const ticketSelections = useSelector((state) => state.ticket.selectedOptions);
  const fixture = useSelector((state) => state.select.currentFixture);
  const [inTicket, setInTicket] = useState(false);
  const dispatch = useDispatch();
  const { fixtureId } = useParams();
  const { options, group, odd, present } = props;

  let odd_split = options.split("-");
  let odd_name = odd_split[0];
  let odd_value = odd_split[1];

  function updateTicketAction(e) {
    const { option } = e.currentTarget.dataset;
    const payload = { fixtureId, group, option };

    dispatch(updateSelectedOptions(payload));
  }

  useEffect(() => {
    if (ticketSelections) {
      if (ticketSelections[fixture.id]) {
        if (ticketSelections[fixture.id][odd.name]) {
          if (ticketSelections[fixture.id][odd.name] === options) {
            setInTicket(true);
          } else {
            setInTicket(false);
          }
        } else {
          setInTicket(false);
        }
      } else {
        setInTicket(false);
      }
    }
  }, [ticketSelections]);

  let disabled_button;
  disabled_button = (
    <div
      style={{ cursor: "not-allowed" }}
      className="p-3 bg-body-tertiary text-center m-2 rounded"
    >
      <span>{odd_name}</span>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        className="bi bi-lock"
        viewBox="0 0 16 16"
      >
        <path d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2m3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2M5 8h6a1 1 0 0 1 1 1v5a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1" />
      </svg>
    </div>
  );

  return (
    <div className="medium flex-grow-1 sans">
      {odd_value != 0 ? (
        <>
          <div
            className={`p-3 bg-body-tertiary text-center me-2 cursor rounded odd ${
              (inTicket || present) && "selected"
            }`}
            data-name={odd_name}
            data-option={options}
            onClick={odd_value !== 0 ? updateTicketAction : null}
          >
            <b>{odd_name}</b>
          </div>
          <div className="text-center small odd-sm">{odd_value}</div>
        </>
      ) : (
        disabled_button
      )}
    </div>
  );
}

function StatsModal(props) {
  const { competition, statLoading, fixture, stats, home_prevs, away_prevs } =
    props;
  return (
    <div
      className="modal fade sans"
      id="fixtureStatModal"
      tabIndex="-1"
      aria-labelledby="fixtureStatModal"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        {competition && (
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="fixtureStatModal">
                <img
                  src={competition.competition_emblem}
                  width="40px"
                  height="40px"
                />
                {/* <Picture image={competition.competition_emblem} /> */}
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>

            <div className="modal-body justify-content-start">
              {statLoading ? (
                <Loading />
              ) : (
                stats && (
                  <div style={{ fontSize: "15px" }}>
                    <div className="between-row">
                      <div className="d-flex flex-column align-items-center col-4 text-center">
                        <Picture
                          image={fixture.home_team_crest}
                          rounded={false}
                        />
                        {fixture.home_team}
                        {/* {stats.form.length > 0 && (
                                                          <span>{stats.form[0]['home_team']['form']}</span>
                                                      )} */}
                      </div>
                      <div
                        data-bs-dismiss="modal"
                        className="col-4 justify-center"
                      >
                        <Logo height="50px" width="50px" />
                      </div>
                      <div className="d-flex flex-column align-items-center col-4 text-center">
                        <Picture
                          image={fixture.away_team_crest}
                          rounded={false}
                        />
                        {fixture.away_team}
                        {/* {stats.form.length > 0 && (
                                                          <span>{stats.form[1]['away_team']['form']}</span>
                                                      )} */}
                      </div>
                    </div>

                    <div>
                      {stats.h2h.length > 0 && (
                        <>
                          <div className="text-center my-2 stat-header">
                            <b>Previous Encounters</b>
                          </div>
                          {stats.h2h.map((f, i) => {
                            return (
                              <div
                                className="between-col align-items-stretch border-bottom mb-3 stat-row"
                                key={i}
                              >
                                <div className="between-row stat-grid">
                                  <span>{f.home_team.name}</span>
                                  <span style={{textAlign:'center'}}>
                                    <span >{f.home_team.goals}</span>
                                    {" "}
                                    -
                                    {" "}
                                    <span>{f.away_team.goals}</span>

                                  </span  >
                                  <span style={{textAlign:'right'}}>{f.away_team.name}</span>
                                </div>
                              </div>
                            );
                          })}
                        </>
                      )}
                      {home_prevs && away_prevs && (
                        <div className="">
                          <div className="text-center my-2 stat-header">
                            <b>{fixture.home_team}'s last five games</b>
                          </div>
                          {home_prevs.length > 0 &&
                            home_prevs.map((f, i) => {
                              return (
                                <div
                                  className="between-col align-items-stretch border-bottom mb-3 stat-row"
                                  key={i}
                                >
                                  <div className="d-flex flex-row justify-content-between stat-grid" >
                                    <span>{f.team_1?.hname}</span>
                                    <span style={{textAlign:'center'}}>
                                      <span>{f.team_1?.hgoals}</span>
                                      {" "}
                                      -
                                      {" "}
                                      <span>{f.team_1?.agoals}</span>
                                    </span>

                                    <span style={{textAlign:'right'}}>{f.team_1?.ateam}</span>
                                  </div>
                                </div>
                              );
                            })}
                          <div className="text-center my-2 stat-header">
                            <b>{fixture.away_team}'s last five games</b>
                          </div>
                          {away_prevs.length > 0 &&
                            away_prevs.map((f, i) => {
                              return (
                                <div
                                  className="between-col align-items-stretch border-bottom mb-3 stat-row"
                                  key={i}
                                >
                                  <div className="d-flex flex-row justify-content-between stat-grid" >
                                    <span>{f.team_2?.hname}</span>
                                    <span style={{textAlign:'center'}}>
                                      <span>{f.team_2?.hgoals}</span>
                                      {" "}
                                      -
                                      {" "}
                                      <span>{f.team_2?.agoals}</span>
                                    </span>

                                    <span style={{textAlign:'right'}}>{f.team_2?.ateam}</span>
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                      )}
                    </div>
                  </div>
                )
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
