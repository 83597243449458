import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
// import { type } from "@testing-library/user-event/dist/type";

function convertObject(inputObject) {
  let transformedObject = {};
  for (let key in inputObject) {
    if (inputObject.hasOwnProperty(key)) {
      let value = inputObject[key];
      let transformedValues = Object.values(value);
      transformedObject[key] = transformedValues;
    }
  }
  return transformedObject;
}

export const createTicketAsync = createAsyncThunk(
  "createTicketAsync",
  async (payload) => {
    
    const data = convertObject(payload.options);

    const amount = payload.amount;
    const body = {
      amount,
      data,
    };
    
    const url = `${process.env.REACT_APP_BASE_URL}/versus`;

    const config = {
      method: "POST",
      headers: {
        Authorization: `Token ${localStorage.getItem("vsrz").token}`,
        reactkey: process.env.REACT_APP_AUTH_KEY,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    };
    const response = await fetch(url, config).then((data) => data.json());
    return response;
  }
);

export const getGamesAsync = createAsyncThunk(
  "getGamesAsync",
  async (payload) => {
    const url = `${process.env.REACT_APP_BASE_URL}/betcode/${payload}`;

    const config = {
      method: "GET",
      headers: {
        reactkey: process.env.REACT_APP_AUTH_KEY,
        "Content-Type": "application/json",
      },
    };

    const response = await fetch(url, config).then((data) => data.json());
    return response;
  }
);

function convertToArray(object) {
  let result = [];

  for (let key in object) {
    for (let key2 in object[key]) {
      result.push(`${key}|${key2}|${object[key][key2]}`);
    }
  }

  return result;
}

export const ticketSlice = createSlice({
  name: "ticket",
  initialState: {
    createTicketState: {
      isErr: false,
      isLoading: false,
      errMessage: "",
      ticketCreated: false,
      ticketPaired: false,
      pairCode: false,
    },
    selectedOptions: {},
    selectedOptionsArray: [],
    totalOdds: 0,
    selectedOptionsCount: 0,
    games: {
      err: false,
      loading: false,
      data: {},
    },

    pairID: 0,
    pairType: "",
    pairStake: 0,
  },
  reducers: {
    updateSelectedOptions: (state, action) => {
      // eslint-disable-next-line
      const { fixtureId, group, option, odd } = action.payload;
      const string = JSON.stringify(state.selectedOptions);
      const json = JSON.parse(string);

      if (json.hasOwnProperty(fixtureId)) {
        

        if (json[fixtureId].hasOwnProperty(group.toUpperCase())) {
          // update option
          if (json[fixtureId][group.toUpperCase()] === option) {
            
            delete json[fixtureId][group.toUpperCase()];
            if (JSON.stringify(json[fixtureId]) === "{}") {
              delete json[fixtureId];
            }
          } else {
            
            json[fixtureId][group.toUpperCase()] = option;
          }

          localStorage.setItem("ticket", JSON.stringify(json));
          return {
            selectedOptions: json,
          };
        } else {
          
          // add group
          let newState = {
            ...state,
            selectedOptions: {
              ...state.selectedOptions,
              [fixtureId]: {
                ...state.selectedOptions[fixtureId],
                [group.toUpperCase()]: option,
              },
            },
          };

          localStorage.setItem("ticket", JSON.stringify(newState));
          return newState;
        }
      } else {
        
        let newState = {
          ...state,
          selectedOptions: {
            ...state.selectedOptions,
            [fixtureId]: {
              [group.toUpperCase()]: option,
            },
          },
        };

        localStorage.setItem("ticket", JSON.stringify(newState));
        return newState;
      }
    },
    deleteSelectedOptions: (state, action) => {
      const string = localStorage.getItem("ticket");
      const json = JSON.parse(string);

      // eslint-disable-next-line
      const { fixtureId, group, option, odd } = action.payload;

      // let newGroup = group;


      for (const id in json) {
        

        if (id === fixtureId) {
          if (json.hasOwnProperty(id)) {
            for (const grp in json[id]) {
              if (json[id][grp] === option) {
                delete json[id][grp];
                
              }
            }
            if (JSON.stringify(json[id]) === "{}") {
              delete json[id];
            }
          }
        }
      }

      
      return {
        selectedOptions: json,
      };

      

      // if (json.hasOwnProperty(fixtureId)) {
      //   // if group exists in fixtureId
      //   if (json[fixtureId].hasOwnProperty(group)) {
      //     // update option
      //     if (json[fixtureId][group] === option) {
      
      //       let arr = "";
      //       delete json[fixtureId][group];
      //       if (JSON.stringify(json[fixtureId]) === "{}") {
      //         delete json[fixtureId];
      //       }
      
      //     }

      //     return {
      //       selectedOptions: json,
      //     };
      //   }
      // }
    },
    updateSelectedOptionsArray: (state, action) => {
      return {
        ...state,
        selectedOptionsArray: convertToArray(state.selectedOptions),
      };
    },
    getTicketCount: (state, action) => {
      let obj = state.selectedOptions;
      let fieldCount = 0;

      for (const key in obj) {
        // eslint-disable-next-line
        for (const subKey in obj[key]) {
          fieldCount++;
        }
      }
      if (JSON.stringify(state.selectedOptions) !== "{}") {
        // const s
        localStorage.setItem("ticket", JSON.stringify(state.selectedOptions));
      }

      

      return {
        ...state,
        // selectedOptions: JSON.parse(localStorage.getItem("ticket")),
        selectedOptionsCount: fieldCount,
      };
    },
    updateGames: (state, action) => {
      let games = {
        ...state.games,
        [action.payload.id]: action.payload.data,
      };
      localStorage.setItem("games", JSON.stringify(games));
      return {
        ...state,
        games,
      };
    },
    setPairDetails: (state, action) => {
      const { id, stake, number_of_games, pairType, points, entry_points, isPublic, max_date, isStreak } = action.payload;
      localStorage.setItem(
        "pairDetails",
        JSON.stringify({ id, stake, number_of_games, pairType, points, entry_points, isPublic, max_date, isStreak })
      );

      if (id === 0 && stake === 0) {
        return {
          ...state,
          pairID: 0,
          pairStake: 0,
          number_of_games: 0,
          pairType: pairType || state.pairType,
          points: points,
          entry_points: entry_points,
          isPublic: isPublic,
          isStreak: isStreak
        };
      } else {
        return {
          ...state,
          pairID: id || state.pairID,
          pairStake: stake || state.pairStake,
          number_of_games: number_of_games || state.selectedOptionsCount,
          pairType: pairType || state.pairType,
          points: points,
          entry_points: entry_points,
          isPublic: isPublic,
          max_date: max_date,
          isStreak: isStreak
        };
      }
    },
    setSelectedOptions: (state, action) => {
      return {
        ...state,
        selectedOptions: action.payload.ticket,
        games: action.payload.games,
      };
    },
    clearStoreTicket: (state, action) => {
      state.selectedOptions = {};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getGamesAsync.pending, (state, action) => {
      // clean state when making a new request
      state.games = {
        loading: true,
        data: {},
        err: false,
      };
    });
    builder.addCase(getGamesAsync.rejected, (state, action) => {
      //   request rejected
      state.games = {
        loading: false,
        err: true,
        data: {},
      };
    });
    builder.addCase(getGamesAsync.fulfilled, (state, action) => {
      

      state.games = {
        loading: false,
        err: false,
        data: action.payload.data,
      };
    });
  },
});

export const {
  updateSelectedOptions,
  updateSelectedOptionsArray,
  deleteSelectedOptions,
  getTicketCount,
  updateGames,
  setPairDetails,
  setSelectedOptions,
  clearStoreTicket,
} = ticketSlice.actions;
export default ticketSlice.reducer;
