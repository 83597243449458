import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// tours => intro, navigation, create a 1v1, select games

export const tourSlice = createSlice({
  name: "tour",
  initialState: {
    tourMode: false,
    // currentTour: "intro",
    currentTour: "main",
    allowButtonClick: "none",
  },
  reducers: {
    setCurrentTour: (state, action) => {
      
      console.log(`tour changed to ${action.payload}`)


      if (action.payload != "") {
        

        if (!localStorage.getItem("sequence")) {
          let newSequence = [];
          newSequence.push(action.payload);
          let data = JSON.stringify(newSequence);
          localStorage.setItem("sequence", data);
        } else {
          let newSequence = JSON.parse(localStorage.getItem("sequence"));
          if (!newSequence.includes(action.payload)) {
            localStorage.setItem("tour", action.payload);
            newSequence.push(action.payload);
            let data = JSON.stringify(newSequence);
            localStorage.setItem("sequence", data);
          }
        }

        return {
          ...state,
          currentTour: action.payload,
          // tourMode: true
        };
      } else {
        localStorage.setItem("tourMode", false);
        return {
          ...state,
          // currentTour: action.payload,
          tourMode: false
        };
      }
    },
    setAllowButtonClick: (state, action) => {
      return {
        ...state,
        allowButtonClick: action.payload,
      };
    },
    setTourMode: (state, action) => {
      if(action.payload){
        localStorage.setItem("tourMode", true);
      }else{
        
        localStorage.setItem("tourMode", false);
      }
      return {
        ...state,
        tourMode: action.payload,
      };
    },
  },
});

export const { setCurrentTour, setAllowButtonClick, setTourMode } =
  tourSlice.actions;
export default tourSlice.reducer;
