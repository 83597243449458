import Layout from "../components/layout";
import '../styles/tournaments.css';
import medal from '../images/medal.png';
import { Picture } from "../components/cards";
import def from '../images/default.png';
import { useSelector } from "react-redux";
import { useEffect } from "react";
import CountdownTimer from "../components/countdown";
import Login from "../components/login";
import { getOrdinalSuffix } from "./league";
import { useMediaQuery } from "react-responsive";
import { Back } from "./select";
import { Loading } from "../App";
import { CupCarousel } from "./cups";
import cash from '../images/cash.png'


export default function Tournaments() {
    const leaderboard = useSelector(state => state.general.weeklyTournament);
    const authenticated = useSelector(state => state.auth.authenticated);
    const isMobile = useMediaQuery({ maxWidth: 768 });
    const tournamentLoading = useSelector(state => state.general.tournamentLoading);

    useEffect(() => {
        
    }, [leaderboard])

    useEffect(() => {
        if ((window.location.pathname === '/leaderboards') && (!isMobile)) {
            window.location.href = '/'
        }
    }, [isMobile, window.location.pathname])

    return (
        <>
            {!isMobile ? (
                <>
                    <div className="between-row d-md-none">
                        <h4>
                            <b>Leaderboards</b>
                        </h4>
                    </div>
                    {leaderboard && (
                        <div className="col-12 justify-center flex-column mx-auto my-3">
                            <div className="col-11 between-row p-3 tournament rounded-4 text-light">
                                <div className="between-col align-items-start">
                                    <h4 className={`seven ${leaderboard.position && ''}`}>Versuz Weekly Leaderboard</h4>
                                    <hr className="col-8"></hr>
                                    <div className="between-row col-12">
                                        <div className="between-col align-items-start">
                                            {/* <div>Week {leaderboard.week}</div> */}
                                            <div className="sans small between-row">Time left: <div className="fs-6 ms-1 sans seven"><CountdownTimer date={leaderboard.end_date} /></div></div>
                                        </div>
                                        {leaderboard.position && (
                                            <h1 className="">{leaderboard.position}<span className="medium">{getOrdinalSuffix(leaderboard.position)}</span></h1>
                                        )}
                                    </div>
                                </div>
                                {/* {leaderboard.position && (
                                    <h1 className="">{leaderboard.position}{getOrdinalSuffix(leaderboard.position)}</h1>

                                )} */}
                                {/* <Picture image={medal} size='150' /> */}
                            </div>
                            <div className="col-11 mt-3 overflow-y-auto small tournament-table" style={{ height: '460px' }}>
                                <TournamentTable leaderboard={leaderboard} />
                            </div>
                        </div>
                    )}
                </>
            ) : (
                <>
                    <Layout>
                        {tournamentLoading ? <Loading /> : (
                            <>
                                <div className="between-row justify-content-start d-md-none">
                                    <Back />
                                    <h4 className="ms-1">
                                        <b>Leaderboards</b>
                                    </h4>
                                </div>
                                {leaderboard && (
                                    <div className="col-12 justify-center flex-column mx-auto my-3">
                                        <div className="col-11 between-row p-3 tournament rounded-4 text-light">
                                            <div className="between-col align-items-start">
                                                <h4 className="seven">Versuz Weekly Leaderboard</h4>
                                                <hr className="col-8"></hr>
                                                <div className="between-row col-12">
                                                    <div className="between-col align-items-start">
                                                        {/* <div>Week {leaderboard.week}</div> */}
                                                        <div className="sans small between-row">Time left: <div className="fs-6 ms-1 sans seven"><CountdownTimer date={leaderboard.end_date} /></div></div>
                                                    </div>
                                                    {leaderboard.position && (
                                                        <h1 className="">{leaderboard.position}<span className="medium">{getOrdinalSuffix(leaderboard.position)}</span></h1>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-11 mt-3 overflow-y-auto small" style={{ height: '400px' }}>
                                            <TournamentTable leaderboard={leaderboard} />
                                        </div>

                                    </div>
                                )}

                            </>
                        )}
                    </Layout>
                </>
            )}
        </>
    )
}

export function TournamentTable(props) {
    const user = useSelector(state => state.general.profile);
    const { leaderboard } = props;

    let users = [];
    let my_stats;
    if (leaderboard) {
        users = leaderboard.data.slice(0, 50);
        my_stats = leaderboard.data.find(u => u.username === user?.username);
    }
    useEffect(() => {
    }, [user])

    return (
        <>
            {users.length > 0 && (
                <table className="table">
                    <thead className="table-dark">
                        <tr>
                            <th>*</th>
                            <th>User</th>
                            <th>
                                <svg width="23" height="23" viewBox="0 0 26 34" fill="none" xmlns="http://www.w3.org/2000/svg" className="me-2">
                                    <path d="M0.00939941 8.85865V25.4124L12.9147 33.6892L25.82 25.4124V8.85865L12.9147 0.581787L0.00939941 8.85865Z" fill="#ECB20C" />
                                    <path d="M8.22915 14.2778L12.9147 4.1516V0.581787L0.00939941 8.85865L8.22915 14.2778Z" fill="url(#paint0_linear_2712_29133)" />
                                    <path d="M19.0201 20.5166L12.9148 30.1192V33.6891L25.8201 25.4122L23.0369 23.6272L19.0201 20.5166Z" fill="url(#paint1_linear_2712_29133)" />
                                    <path d="M12.6915 25.8801L2.79241 23.6274L0.00939941 25.4124L12.9147 33.6893V30.1195L12.6915 25.8801Z" fill="url(#paint2_linear_2712_29133)" />
                                    <path d="M13.1353 8.54164L23.0369 10.6436L25.8201 8.85865L12.9148 0.581787V4.1516L13.1353 8.54164Z" fill="url(#paint3_linear_2712_29133)" />
                                    <path d="M8.67527 20.0789L3.21023 10.9351L0.00939941 8.85864V25.4124L2.79241 23.6274L8.67527 20.0789Z" fill="url(#paint4_linear_2712_29133)" />
                                    <path d="M17.1539 20.0789L22.619 10.9351L25.82 8.85864V25.4124L23.0368 23.6274L17.1539 20.0789Z" fill="url(#paint5_linear_2712_29133)" />
                                    <g filter="url(#filter0_i_2712_29133)">
                                        <path d="M2.7926 10.6437V23.6274L12.9149 30.1194L23.037 23.6274V10.6437L12.9149 4.15161L2.7926 10.6437Z" fill="url(#paint6_diamond_2712_29133)" />
                                    </g>
                                    <path d="M5.57699 10.6885L5.82116 9.71166C5.85245 9.58635 5.91734 9.4718 6.00871 9.38042C6.10009 9.28904 6.21464 9.22415 6.33995 9.19286L7.31679 8.9487C7.34311 8.94208 7.34311 8.90466 7.31679 8.89821L6.33995 8.65404C6.21464 8.62275 6.10009 8.55786 6.00871 8.46649C5.91734 8.37511 5.85245 8.26056 5.82116 8.13525L5.57699 7.15841C5.57037 7.13209 5.53296 7.13209 5.5265 7.15841L5.28234 8.13525C5.25105 8.26056 5.18616 8.37511 5.09478 8.46649C5.00341 8.55786 4.88885 8.62275 4.76354 8.65404L3.78671 8.89821C3.76039 8.90483 3.76039 8.94224 3.78671 8.9487L4.76354 9.19286C4.88885 9.22415 5.00341 9.28904 5.09478 9.38042C5.18616 9.4718 5.25105 9.58635 5.28234 9.71166L5.5265 10.6885C5.53313 10.7148 5.57054 10.7148 5.57699 10.6885Z" fill="white" />
                                    <path d="M22.9739 21.4029L23.157 20.6702C23.1805 20.5762 23.2292 20.4903 23.2977 20.4217C23.3663 20.3532 23.4522 20.3045 23.5462 20.281L24.2789 20.0978C24.2986 20.0928 24.2986 20.0648 24.2789 20.0599L23.5462 19.8766C23.4522 19.8531 23.3663 19.8044 23.2977 19.7359C23.2292 19.6674 23.1805 19.5815 23.157 19.4874L22.9739 18.7548C22.969 18.7351 22.941 18.7351 22.936 18.7548L22.753 19.4874C22.7294 19.5815 22.6808 19.6674 22.6122 19.7359C22.5437 19.8044 22.4578 19.8531 22.3638 19.8766L21.6311 20.0599C21.6114 20.0648 21.6114 20.0928 21.6311 20.0978L22.3638 20.281C22.4578 20.3045 22.5437 20.3532 22.6122 20.4217C22.6808 20.4903 22.7294 20.5762 22.753 20.6702L22.936 21.4029C22.941 21.4226 22.969 21.4226 22.9739 21.4029Z" fill="white" />
                                    <path d="M22.1269 15.2446L21.1453 16.9684L21.0734 17.1121H17.3385L16.9555 17.8064H20.6664L20.0918 18.788L19.5891 19.6499H15.8781L14.8486 21.4455L13.3642 23.9833H9.74907L9.29418 21.4455L8.98294 19.6499H5.63112L5.48747 18.788L5.31988 17.8064H8.64775L8.52805 17.1121H5.17623L5.15229 16.9684L4.86499 15.2446H8.19287L7.78586 12.8744L7.47462 11.1746H11.6165L11.9038 12.8744L12.3348 15.2446L12.5023 16.2502L12.6699 17.1121L12.7657 17.7106L13.1009 17.1121L13.6037 16.2502L14.1783 15.2446L15.5669 12.8744L16.5245 11.1746H20.7622L19.8045 12.8744L18.4159 15.2446H22.1269Z" fill="black" />
                                    <defs>
                                        <filter id="filter0_i_2712_29133" x="2.7926" y="4.15161" width="20.3503" height="26.0737" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                            <feFlood floodOpacity="0" result="BackgroundImageFix" />
                                            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                            <feOffset dx="0.105927" dy="0.105927" />
                                            <feGaussianBlur stdDeviation="0.222446" />
                                            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                                            <feColorMatrix type="matrix" values="0 0 0 0 0.529412 0 0 0 0 0.713726 0 0 0 0 0.0196078 0 0 0 1 0" />
                                            <feBlend mode="normal" in2="shape" result="effect1_innerShadow_2712_29133" />
                                        </filter>
                                        <linearGradient id="paint0_linear_2712_29133" x1="6.5028" y1="4.77067" x2="8.42669" y2="7.14785" gradientUnits="userSpaceOnUse">
                                            <stop stopColor="#D3FF58" />
                                            <stop offset="1" stopColor="#ACE805" />
                                        </linearGradient>
                                        <linearGradient id="paint1_linear_2712_29133" x1="18.9703" y1="28.9785" x2="17.5808" y2="26.8448" gradientUnits="userSpaceOnUse">
                                            <stop stopColor="#D3FF58" />
                                            <stop offset="1" stopColor="#ACE805" />
                                        </linearGradient>
                                        <linearGradient id="paint2_linear_2712_29133" x1="6.2871" y1="29.7353" x2="8.60171" y2="27.2939" gradientUnits="userSpaceOnUse">
                                            <stop stopColor="#CAFF37" />
                                            <stop offset="1" stopColor="#ACE805" />
                                        </linearGradient>
                                        <linearGradient id="paint3_linear_2712_29133" x1="19.4922" y1="4.59325" x2="17.1771" y2="7.06458" gradientUnits="userSpaceOnUse">
                                            <stop stopColor="#CAFF37" />
                                            <stop offset="1" stopColor="#86B504" />
                                        </linearGradient>
                                        <linearGradient id="paint4_linear_2712_29133" x1="-1.14256" y1="17.1328" x2="0.675385" y2="18.9862" gradientUnits="userSpaceOnUse">
                                            <stop stopColor="#CAFF37" />
                                            <stop offset="1" stopColor="#ACE805" />
                                        </linearGradient>
                                        <linearGradient id="paint5_linear_2712_29133" x1="25.82" y1="17.1355" x2="22.6319" y2="17.1355" gradientUnits="userSpaceOnUse">
                                            <stop stopColor="#BDFF05" />
                                            <stop offset="1" stopColor="#86B504" />
                                        </linearGradient>
                                        <radialGradient id="paint6_diamond_2712_29133" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(12.9062 16.4708) rotate(-90) scale(13.9956 13.9956)">
                                            <stop stopColor="#BDFF05" />
                                            <stop offset="1" stopColor="#D3FF58" />
                                        </radialGradient>
                                    </defs>
                                </svg>
                            </th>
                            {/* <th>
                                <Picture image={cash} size='23'/>
                            </th> */}
                        </tr>
                    </thead>
                    <tbody className="sans">
                        {users.map((user, i) => {
                            return (
                                <tr className="align-middle" key={user.username}>
                                    <td>{i + 1}</td>
                                    <td className="align-row-center">
                                        <Picture image={user.profile_pic} size='30' />
                                        <span className="ms-1">{user.username}</span>
                                    </td>
                                    <td>{user.versuz_points}</td>
                                    {/* <td>
                                        {i+1===1 && '5,000'}
                                        {i+1 === 2 && '3,000'}
                                        {i+1 === 3 && '2,000'}
                                    </td> */}
                                </tr>
                            )
                        })}
                        {leaderboard.position > users.length && (
                            <tr className="align-middle">
                                <td>{leaderboard.position}</td>
                                <td className="align-row-center">
                                    <Picture image={my_stats.profile_pic} size='30' />
                                    <span className="ms-1">{my_stats.username}</span>
                                </td>
                                <td>{my_stats.versuz_points}</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            )}
        </>
    )
}