import { useLocation, useNavigate, useParams } from "react-router-dom";
import '../styles/login.css';
import '../index.css';
import { Logo } from "./layout";
import { useEffect, useState } from "react";
import { setAuthenticated } from "../redux/slices/authSlice";
import { useDispatch } from "react-redux";
import userEvent from "@testing-library/user-event";
import { Error } from "../pages/profile";

export default function Login({ reload = false }) {
    const dispatch = useDispatch();
    const [disabled, setDisabled] = useState(true);
    const [details, setDetails] = useState({
        username: '',
        password: ''
    });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();

    function handleChange(e) {
        // if (!emojiRegex.test(e.target.value)) {
        setDetails({
            ...details,
            username: e.target.value
        });
        // }
    }
    function handlePasswordChange(e) {
        setDetails({
            ...details,
            password: e.target.value
        });
    }

    useEffect(() => {
        if (details.username !== '' && details.password !== '') {
            setDisabled(false)
        } else {
            setDisabled(true)
        }
    }, [details])

    async function handleSubmit(e) {
        e.preventDefault();
        setLoading(true);
        const url = process.env.REACT_APP_AUTH_ENDPOINT_LOGIN;
        const config = {
            method: "POST",
            headers: {
                reactkey: process.env.REACT_APP_AUTH_KEY,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(details),
        };

        const response = await fetch(url, config)
            .then((data) => data.json())
            .catch((err) => err);

        if (response.success) {
            dispatch(setAuthenticated({ status: true, token: response.token, user: response.user }));

            const authObj = JSON.stringify({
                username: response.user.username,
                token: response.token,
                user: response.user,
                hash: ""
            });
            localStorage.setItem("vsrz", authObj);
            localStorage.setItem("competitions", "")

            // window.location.href = '/explore';
            if (reload) {
                window.location.reload()
            } else {
                if (localStorage.getItem("prev")) {
                    window.location.href = localStorage.getItem("prev")
                } else {
                    window.location.href = "/explore";

                }
                localStorage.removeItem("prev")
            }
            setError()

        }
        else {
            setError(response.message);
        }
        setLoading(false);
    }

    return (
        <div className="auth_page justify-center">
            <div className="w-50 h-75 auth-body">
                <div className="align-col-center my-5 cursor">
                    <Logo clickable={true} height='50' width='50' />
                    <h6>
                        <b>Versuz</b>
                    </h6>
                </div>
                <div className="col-12 p-3 auth_form">
                    <div className="text-center mb-3">
                        <h4>
                            <b>Login</b>
                        </h4>
                    </div>
                    {error && !loading && <Error error={error} setError={setError} />}
                    <form className="sans">
                        <div className="mb-3 col-12">
                            <label htmlFor="username" className="form-label">Username</label>
                            <input type="text" className="form-control" id="username" aria-describedby="username" value={details.username} onChange={handleChange} />
                        </div>
                        <div>
                            <label htmlFor="password" className="form-label">Password</label>
                            <Password newPassword={details} setNewPassword={setDetails} name='password' />
                        </div>
                        <button type="submit" className={`btn ${disabled || loading ? 'btn-secondary' : 'btn-primary'} w-100 my-3 py-2`} disabled={disabled || loading} onClick={(e) => handleSubmit(e)}>
                            {loading ? (
                                <div className="spinner-grow text-light" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            ) : "Start Playin'"}
                        </button>
                        <div className="text-center">
                            <div className="d-flex flex-column flex-md-row justify-content-center align-items-center">
                                <span className="small me-1">Forgot your password?</span>
                                <span className="medium">
                                    <a className="links" href="/find-account">
                                        Reset Password
                                    </a>
                                </span>
                            </div>
                            <hr />
                            <div>
                                <span className="small">New here? </span>
                                <span className="medium">
                                    <a className="links" href="/signup">Create Account</a>
                                </span>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export function LoginButton(props) {
    const navigate = useNavigate();
    const location = useLocation();
    const { active_modal } = props;

    const modalAttributes = active_modal && { 'data-bs-dismiss': 'modal' };

    function goToLogin() {
        localStorage.setItem("prev", location.pathname);
        navigate('/login');
    }

    return (
        <button className="btn btn-primary rounded-3 medium sans" onClick={goToLogin} {...modalAttributes} style={{background:'#5E57F2'}}>Login</button>
    )
}

export function Signup() {
    const [loading, setLoading] = useState(false);
    const [maxDate, setMaxDate] = useState('');
    const [userDetails, setUserDetails] = useState({
        'firstname': '',
        'lastname': '',
        'email': '',
        'phone_number': '',
        'password': '',
        'confirm_password': '',
        'dob': '',
        'username': ''
    })
    const [checked, setIsChecked] = useState(false);
    const [validEmail, setValidEmail] = useState(false);
    const [validNumber, setValidNumber] = useState(false);
    const emailRegex = /^[\w\.-]+@[a-zA-Z\d\.-]+\.[a-zA-Z]{2,}$/;
    const phoneRegex = /^(08|09|07)\d{9}$/;
    // const emojiRegex = /[\u{1F600}-\u{1F6FF}]/u;
    const [forms, setForms] = useState({
        'form_one_valid': false,
        'form_two_valid': false,
    });
    const [view, setView] = useState(1);
    const [error, setError] = useState();
    const navigate = useNavigate();
    const { ref } = useParams();

    function handleChange(e) {
        const { name, value } = e.target;
        if(name === 'username'){
            let new_value = value.replace(/[^a-zA-Z0-9-_]/gm, '');
            setUserDetails({
                ...userDetails,
                [name]: new_value
            })
        }else{
            if (name === 'email') {
                emailRegex.test(value) ? setValidEmail(true) : setValidEmail(false);
            } else if (name === 'phone_number') {
                phoneRegex.test(value) ? setValidNumber(true) : setValidNumber(false);
            }
            setUserDetails({
                ...userDetails,
                [name]: value
            })
        }
    }

    const signup = async () => {

        setLoading(true)

        const url = process.env.REACT_APP_AUTH_ENDPOINT_REGISTER;
        const config = {
            method: "POST",
            headers: {
                reactkey: process.env.REACT_APP_AUTH_KEY,
                "Content-Type": "application/json",
            },
            // body: JSON.stringify(user),
            body: JSON.stringify({ ...userDetails, ref: ref || '' }),
        };

        const response = await fetch(url, config)
            .then((data) => data.json())
            .catch((err) => err);

        
        if (response.success) {
            setError();
            navigate(`/verify-email`);
        } else {
            setError(response.message)
        }
        setLoading(false)
    }

    function handleSubmit(e) {
        e.preventDefault();
        signup();
    }

    function validate() {
        if ((userDetails.lastname !== '') && ((userDetails.firstname !== '')) && (userDetails.email !== '') && (userDetails.password !== '') & (userDetails.confirm_password !== '') && (userDetails.password === userDetails.confirm_password) && validEmail && checked && validNumber && userDetails.password.length >= 7) {
            setForms({ ...forms, form_one_valid: true })
        }
        else {
            setForms({ ...forms, form_one_valid: false })
        }
    }
    function validate_two() {
        if (userDetails.username !== '' && userDetails.dob !== '' && validNumber) {
            setForms({ ...forms, form_two_valid: true })
        }
        else {
            setForms({ ...forms, form_two_valid: false })
        }
    }

    useEffect(() => {
        if (view === 1) {
            validate()
        } else {
            validate_two()
        }
    }, [userDetails, checked])

    useEffect(() => {
        const calculateEighteenYearsAgo = () => {
            const today = new Date();
            const eighteenYearsAgo = new Date(today.setFullYear(today.getFullYear() - 18));
            return eighteenYearsAgo.toISOString().split('T')[0]; // Format as YYYY-MM-DD
        };

        setMaxDate(calculateEighteenYearsAgo());
    }, []);

    return (
        <div className="auth_page justify-center create-account">
            <div className="col-10 col-md-6 mx-auto cre-ap">
                <div className="align-col-center cursor">
                    <Logo clickable={true} height='50' width='50' />
                    <h6>
                        <b>Versuz</b>
                    </h6>
                </div>
                <div className="col-12 p-3 auth_form rounded">
                    <div className="text-center mb-3">
                        <h4>
                            <b>Create Account</b>
                        </h4>
                    </div>
                    {error && !loading && <Error error={error} setError={setError} />}
                    {view === 1 ? (
                        <div>
                            <form className="sans">
                                <div className="between-row">
                                    <div className="mb-3 col-5">
                                        <label htmlFor="firstname" className="form-label">First Name</label>
                                        <input type="text" className="form-control" id="username" aria-describedby="firstname" value={userDetails.firstname} onChange={handleChange} name='firstname' />
                                    </div>
                                    <div className="mb-3 col-5">
                                        <label htmlFor="lastname" className="form-label">Last Name</label>
                                        <input type="text" className="form-control" id="lastname" aria-describedby="lastname" value={userDetails.lastname} onChange={handleChange} name='lastname' />
                                    </div>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="email" className="form-label">Email Address</label>
                                    <input type="text" className="form-control" id="email" aria-describedby="email" value={userDetails.email} onChange={handleChange} name='email' />
                                    {!validEmail && <ErrorMessage text='Enter a valid Email Address' />}
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="phone-number" className="form-label">Phone Number</label>
                                    <input type="number" className="form-control" id="phone-number" aria-describedby="phone-number" value={userDetails.phone_number} name='phone_number' onChange={handleChange} />
                                    {!validNumber && <ErrorMessage text='Enter a valid phone number' />}
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="password" className="form-label">Password</label>
                                    {/* <input type="password" className="form-control" id="password" placeholder="*******" value={userDetails.password} onChange={handleChange} name='password' /> */}
                                    <Password newPassword={userDetails} setNewPassword={setUserDetails} name='password' />
                                    {(userDetails.password.length < 7) && <ErrorMessage text='Password should be at least 7 characters' />}
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="confirm-password" className="form-label">Confirm Password</label>
                                    <Password newPassword={userDetails} setNewPassword={setUserDetails} name='confirm_password' />
                                    {(userDetails.password !== userDetails.confirm_password) && <ErrorMessage text='Passwords do not match' />}
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value={checked} id="flexCheckDefault" name='agree' checked={checked} onChange={() => setIsChecked(!checked)} />
                                    <label className="form-check-label" htmlFor="flexCheckDefault">
                                        I agree to <a className="links" href="/terms">Terms</a>
                                    </label>
                                </div>
                                <button type="button" className={`btn ${loading ? 'btn-secondary' : 'btn-primary'} w-100 my-3 py-2`} disabled={!forms.form_one_valid} onClick={() => setView(2)}>
                                    Continue
                                </button>
                                <div className="text-center">
                                    <hr />
                                    <div>
                                        <span className="small">Already have an account? </span>
                                        <span className="medium">
                                            <a className="links" href="/login">Login</a>
                                        </span>
                                    </div>
                                </div>
                            </form>
                        </div>
                    ) : (
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-arrow-left my-2 cursor" viewBox="0 0 16 16" onClick={() => setView(1)}>
                                <path fillRule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8" />
                            </svg>
                            <form className="sans">
                                <div className="mb-3">
                                    <label htmlFor="username" className="form-label">Username</label>
                                    <input type="text" className="form-control" id="username" aria-describedby="username" value={userDetails.username} name="username" onChange={handleChange} />
                                </div>

                                <div className="mb-3">
                                    <label htmlFor="dob" className="form-label">Date of birth</label>
                                    <input type="date" className="form-control" id="dob" aria-describedby="dob" value={userDetails.dob} onChange={handleChange} name="dob" max={maxDate} />
                                </div>
                                <button type="submit" className={`btn ${loading ? 'btn-secondary' : 'btn-primary'} w-100 my-3 py-2`} disabled={!forms.form_two_valid} onClick={(e) => handleSubmit(e)}>
                                    {loading ? (
                                        <div className="spinner-grow text-light" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                    ) : "Start Playin'"}
                                </button>
                                <div className="text-center">
                                    <hr />
                                    <div>
                                        <span className="small">Already have an account? </span>
                                        <span className="medium">
                                            <a className="links" href="/login">Login</a>
                                        </span>
                                    </div>
                                </div>
                            </form>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export function VerifyEmail() {
    const [token, setToken] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const validate = async () => {
        setLoading(true)
        const url = `${process.env.REACT_APP_BASE_URL}/accounts/verify_email/${token}`;
        const config = {
            method: "GET",
            headers: {
                reactkey: process.env.REACT_APP_AUTH_KEY,
                "Content-Type": "application/json",
            },
        };

        const response = await fetch(url, config)
            .then((data) => data.json())
            .catch((err) => err);

        if (response.success) {
            navigate("/login");
            setError();
        } else {
            setError(response.message)
        }
        setLoading(false)
    }

    return (
        <div className="auth_page justify-center">
            <div className="col-10 col-md-6 ">
                <div className="align-col-center cursor">
                    <Logo clickable={true} height='50' width='50' />
                    <h6>
                        <b>Versuz</b>
                    </h6>
                </div>
                <div className="col-12 p-3 auth_form rounded sans">
                    {error && <Error error={error} setError={setError} />}
                    <div className="text-center mb-3">
                        <svg width="259" height="167" viewBox="0 0 259 167" fill="none" xmlns="http://www.w3.org/2000/svg" className="paper-plane">
                            <g clipPath="url(#clip0_924_44375)">
                                <path d="M-344.469 290.778C-329.009 349.806 -234.844 421.015 -130.139 375.573C-17.2349 326.617 -21.4513 140.864 124.246 62.1594" stroke="#263238" strokeWidth="1.7568" strokeMiterlimit="10" strokeDasharray="9.39 9.39" />
                                <path d="M129.399 59.5828C130.805 58.88 132.21 58.1773 133.616 57.4746" stroke="#263238" strokeWidth="1.7568" strokeMiterlimit="10" />
                                <path d="M124.48 31.9425L109.957 72.2318L140.643 48.3393L258.7 5.47339L124.48 31.9425Z" fill="#FE4E40" />
                                <path opacity="0.1" d="M124.48 31.9425L109.957 72.2318L140.643 48.3393L258.7 5.47339L124.48 31.9425Z" fill="black" />
                                <path d="M144.625 57.7092L109.957 72.2321L140.643 48.3396L144.625 57.7092Z" fill="#263238" />
                                <path d="M140.643 48.3393L258.7 5.47339L161.959 99.8722L140.643 48.3393Z" fill="#FE4E40" />
                                <path d="M124.48 31.9427L75.5242 0.320312L258.7 5.4736L124.48 31.9427Z" fill="#FE4E40" />
                            </g>
                            <defs>
                                <clipPath id="clip0_924_44375">
                                    <rect width="258.4" height="166.359" fill="white" transform="translate(0.299988 0.320312)" />
                                </clipPath>
                            </defs>
                        </svg>

                    </div>
                    <h3>
                        <b>Verify your account</b>
                    </h3>
                    <div>A verification code has been sent to the mail provided</div>
                    <div className="text-center justify-center">
                        <input value={token} placeholder="Enter verification code" className="p-3 rounded border text-center my-3" onChange={(e) => setToken(e.target.value)} />
                    </div>
                    <div className="text-center between-col">
                        <div>
                            Didn't receive the code? <a className="links">Resend</a>
                        </div>
                        <button className="btn orange my-3" onClick={validate}>
                            Verify
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export function FindAccount() {
    const [error, setError] = useState();
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const [view, setView] = useState(1);
    const navigate = useNavigate();

    const submit = async () => {
        setLoading(true)

        const url = `${process.env.REACT_APP_BASE_URL}/accounts/find_account/${email}`;
        const config = {
            method: "GET",
            headers: {
                reactkey: process.env.REACT_APP_AUTH_KEY,
                "Content-Type": "application/json",
            },
        };

        const response = await fetch(url, config)
            .then((data) => data.json())
            .catch((err) => err);


        if (response.success) {
            setView(2);
            setError();
        } else {
            setError(response.message)
        }
        setLoading(false)
    };


    return (
        <div className="auth_page justify-center">
            <div className="col-10 col-md-6 ">
                <div className="align-col-center cursor">
                    <Logo clickable={true} height='50' width='50' />
                    <h6>
                        <b>Versuz</b>
                    </h6>
                </div>
                <div className="col-12 col-md-10 p-3 mx-auto auth_form rounded">
                    {error && <Error error={error} setError={setError} />}
                    {view === 1 ? (
                        <div className="">
                            <h3>Find your Versuz account</h3>
                            <div className="sans">
                                <div className="medium my-3">
                                    Enter the email address associated with your account to get a password reset link
                                </div>
                                <div className="justify-center">
                                    <input placeholder="shepeteri@shepetepon.com" value={email} onChange={(e) => setEmail(e.target.value)} className="p-1 rounded border medium w-100 p-2" />
                                </div>
                                <button className="btn orange w-100 my-3" disabled={email === '' || loading} onClick={submit}>Continue</button>
                            </div>
                        </div>
                    ) : (
                        <>
                            <div className="text-center mb-3">
                                <svg width="259" height="167" viewBox="0 0 259 167" fill="none" xmlns="http://www.w3.org/2000/svg" className="paper-plane">
                                    <g clipPath="url(#clip0_924_44375)">
                                        <path d="M-344.469 290.778C-329.009 349.806 -234.844 421.015 -130.139 375.573C-17.2349 326.617 -21.4513 140.864 124.246 62.1594" stroke="#263238" strokeWidth="1.7568" strokeMiterlimit="10" strokeDasharray="9.39 9.39" />
                                        <path d="M129.399 59.5828C130.805 58.88 132.21 58.1773 133.616 57.4746" stroke="#263238" strokeWidth="1.7568" strokeMiterlimit="10" />
                                        <path d="M124.48 31.9425L109.957 72.2318L140.643 48.3393L258.7 5.47339L124.48 31.9425Z" fill="#FE4E40" />
                                        <path opacity="0.1" d="M124.48 31.9425L109.957 72.2318L140.643 48.3393L258.7 5.47339L124.48 31.9425Z" fill="black" />
                                        <path d="M144.625 57.7092L109.957 72.2321L140.643 48.3396L144.625 57.7092Z" fill="#263238" />
                                        <path d="M140.643 48.3393L258.7 5.47339L161.959 99.8722L140.643 48.3393Z" fill="#FE4E40" />
                                        <path d="M124.48 31.9427L75.5242 0.320312L258.7 5.4736L124.48 31.9427Z" fill="#FE4E40" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_924_44375">
                                            <rect width="258.4" height="166.359" fill="white" transform="translate(0.299988 0.320312)" />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </div>
                            <div>
                                <h3>
                                    <b>Reset Password Link</b>
                                </h3>
                                <div className="medium">
                                    A reset password link has been sent to your mail. Kindly click the link to set a new password.
                                </div>
                            </div>
                            <button className="btn orange w-100 my-3" onClick={() => navigate('/login')}>Back to Login</button>

                        </>
                    )}
                </div>
            </div>
        </div>
    )
}

export function ChangePassword() {
    const [error, setError] = useState(false);
    const [newPassword, setNewPassword] = useState({
        'password': '',
        'password_two': ''
    })
    const [loading, setLoading] = useState(false);
    const [disabled, setDisabled] = useState(true);
    const [validToken, setValidToken] = useState(false);
    const { token } = useParams();
    const navigate = useNavigate();

    function validate() {
        if (
            newPassword.password === '' ||
            newPassword.password_two === '' ||
            newPassword.password !== newPassword.password_two ||
            newPassword.password.length < 7 ||
            newPassword.password_two.length < 7
        ) {
            setDisabled(true);
        } else {
            setDisabled(false);
        }
    }

    const getTokenValidity = async () => {
        setLoading(true);
        const url = `${process.env.REACT_APP_BASE_URL}/accounts/reset-password/${token}`;
        const config = {
            method: "GET",
            headers: {
                reactkey: process.env.REACT_APP_AUTH_KEY,
                "Content-Type": "application/json",
            },
        };

        const response = await fetch(url, config)
            .then((data) => data.json())
            .catch((err) => err);

        if (response.success) {
            setValidToken(true);
        } else {
            setValidToken(false);
            navigate('/')
        }
        setLoading(false);
    };

    const reset = async () => {
        setLoading(true);
        const url = `${process.env.REACT_APP_BASE_URL}/accounts/reset-password/${token}`;
        const config = {
            method: "POST",
            headers: {
                reactkey: process.env.REACT_APP_AUTH_KEY,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ new_password: newPassword.password }),
        };

        const response = await fetch(url, config)
            .then((data) => data.json())
            .catch((err) => err);


        if (response.success) {
            window.location.href = "/login"
        } else {
            setError(response.message)
        }
        setLoading(false);
    }

    useEffect(() => {
        validate();
    }, [newPassword])

    useEffect(() => {
        getTokenValidity();
    }, [])

    return (
        <div className="auth_page justify-center">
            <div className="col-10 col-md-6 ">
                <div className="align-col-center cursor">
                    <Logo clickable={true} height='50' width='50' />
                    <h6>
                        <b>Versuz</b>
                    </h6>
                </div>
                <div className="col-12 p-3 auth_form rounded sans">
                    {error && <Error error={error} setError={setError} />}
                    {loading && 'Validating Token...'}
                    {validToken && (
                        <>
                            <p className="text-center">
                                <b>Enter New Password</b>
                            </p>
                            <div className="col-10 mx-auto">
                                <div className="d-flex flex-column flex-md-row align-items-md-center justify-content-md-between">
                                    <div className="medium">New Password</div>
                                    <Password newPassword={newPassword} setNewPassword={setNewPassword} name='password' col='10' />
                                </div>
                                <div className="d-flex flex-column flex-md-row align-items-md-center mt-4 justify-content-md-between">
                                    <div className="medium">Confirm Password</div>
                                    <Password newPassword={newPassword} setNewPassword={setNewPassword} name='password_two' col='10' />
                                </div>
                            </div>
                            <div className="justify-center mt-5">
                                <button className="btn orange" disabled={disabled} onClick={reset}>Continue</button>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    )
}

export function Password(props) {
    const { newPassword, setNewPassword, name, col = 12 } = props;
    const [visible, setVisible] = useState(false);

    return (
        <div className={`between-row border p-2 rounded-3 col-12 col-md-${col}`}>
            <input
                type={`${visible ? 'text' : 'password'}`}
                value={newPassword[name]}
                placeholder="*****"
                onChange={(e) => setNewPassword({
                    ...newPassword,
                    [name]: e.target.value
                })}
                className="w-75 fcs"
                style={{ border: 'none' }}
                name={name} />
            <div className="justify-center" onClick={() => setVisible(!visible)}>
                {!visible ? (
                    <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M3.33341 1.33325L16.6667 14.6666M11.6667 9.86334C11.2245 10.2592 10.6404 10.4999 10.0001 10.4999C8.61937 10.4999 7.50008 9.38063 7.50008 7.99992C7.50008 7.35962 7.74079 6.77555 8.13666 6.33325M16.3399 11.0063C16.816 10.5918 17.2419 10.1748 17.6087 9.789C18.575 8.77228 18.575 7.22756 17.6087 6.21083C15.9788 4.49603 13.1797 2.16659 10.0001 2.16659C9.25727 2.16659 8.53522 2.29372 7.844 2.51045M5.41675 3.66941C4.20128 4.44528 3.16478 5.39724 2.3915 6.21083C1.42516 7.22756 1.42516 8.77227 2.3915 9.789C4.02132 11.5038 6.82048 13.8333 10.0001 13.8333C11.5568 13.8333 13.0224 13.2749 14.3041 12.5036" stroke="#141B20" strokeWidth="1.5" strokeLinecap="round" />
                    </svg>
                ) : (
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.99998 1.66675V3.33341M17.5 4.16675L16.25 5.41675M2.49998 4.16675L3.74998 5.41675M10 18.3334C13.0035 18.3334 15.6778 16.3788 17.3977 14.7325C18.6452 13.5382 18.6452 11.4619 17.3977 10.2677C15.6778 8.62134 13.0035 6.66675 10 6.66675C6.9965 6.66675 4.32218 8.62134 2.60234 10.2677C1.35478 11.4619 1.35478 13.5382 2.60234 14.7325C4.32218 16.3788 6.9965 18.3334 10 18.3334ZM12.5 12.5001C12.5 13.8808 11.3807 15.0001 9.99998 15.0001C8.61927 15.0001 7.49998 13.8808 7.49998 12.5001C7.49998 11.1194 8.61927 10.0001 9.99998 10.0001C11.3807 10.0001 12.5 11.1194 12.5 12.5001Z" stroke="#141B20" strokeWidth="1.5" strokeLinecap="round" />
                    </svg>

                )}
            </div>
        </div>
    )
}

function ErrorMessage(props) {
    const { text } = props
    return (
        <div className="small text-danger">
            {text}
        </div>
    )
}