import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const getAvailableGamesAsync = createAsyncThunk(
  "getAvailableGamesAsync",
  async (payload) => {
    const url = process.env.REACT_APP_BASE_URL;
    const config = {
      method: "GET",
      headers: {
        reactkey: process.env.REACT_APP_AUTH_KEY,
        "Content-Type": "application/json",
        "Authorization": `Token ${JSON.parse(localStorage.getItem("vsrz")).token}`
      },
      // body: JSON.stringify(payload),
    };

    const response = await fetch(url, config)
      .then((data) => data.json())
      .catch((err) => err);

      

    return response.explore_page;
  }
);

export const exploreSlice = createSlice({
  // Authentication state
  name: "explore",
  initialState: {
    games: [],
    available_1v1:[],
    available_league:[],
    available_tournament:[],
    

    ticket_count:0,
    loading:false,
    error:false,
    errMessage: "",
    user:{}

  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAvailableGamesAsync.pending, (state, action) => {
      // clean state when making a new request
      state.loading = true
    });
    builder.addCase(getAvailableGamesAsync.rejected, (state, action) => {
      //   request rejected
      state.login.loading = false;
      state.login.isErr = true;
      state.login.errMessage = "Unable to fetch games";
    });
    builder.addCase(getAvailableGamesAsync.fulfilled, (state, action) => {
        state.loading = false
      if (!action.payload.success) {
        state.error = true;
        state.errMessage = "Unable to fetch games"
      } else {
        
        state.games = action.payload.unpaired_betcodes
        const oneVone = action.payload.unpaired_betcodes.filter(game => game.type === "1v1")
        state.available_1v1 = oneVone
        
        const league = action.payload.unpaired_betcodes.filter(game => game.type === "league")
        state.available_league = league

        const tournament = action.payload.unpaired_betcodes.filter(game => game.type === "tournament")
        state.available_tournament = tournament
        

        state.ticket_count = action.payload.ticket_number
        state.user = action.payload.user
        
      }
    });
  },
});

export const { logout, getSavedCridentials } = exploreSlice.actions;
export default exploreSlice.reducer;
