import { useNavigate, useParams } from "react-router-dom";
import Regions, { ColumnHeader } from "./select";
import premier_league_logo from "../images/premier_league_logo.webp";
import "../styles/fixtures.css";
import { Logo, LayoutHeader } from "../components/layout";
import { Loading, convertTo12HourFormat } from "../App";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateSelectedOptions } from "../redux/slices/ticketSlice";
import { setCurrentFixture } from "../redux/slices/selectSlice";
import { useMediaQuery } from "react-responsive";
import { Back, ViewSelections } from "./select";
import { Picture } from "../components/cards";
import Fixture from "../components/fixture";
import { setTourMode } from "../redux/slices/tourSlice";

import fixturesSteps from "../components/tour/fixturesTour";

import {  matchSteps } from "../components/steps";
import { setCurrentTour, setAllowButtonClick } from "../redux/slices/tourSlice";
import Joyride, {
  ACTIONS,
  EVENTS,
  ORIGIN,
  STATUS,
  CallBackProps,
} from "react-joyride";

export default function Fixtures() {
  const { competitionId } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [fixtures, setFixtures] = useState([]);
  const [filteredFixtures, setFilteredFixtures] = useState([]);
  const [competition, setCompetition] = useState({
    id: "",
    competition_name: "",
    competition_emblem: "",
  });
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const [pairDetails, setPairDetails] = useState();
  const tourMode = useSelector((state) => state.tour.tourMode);

  const getFixtures = async () => {
    setLoading(true);
    const url = `${process.env.REACT_APP_BASE_URL}/fixtures/${competitionId}`;
    const config = {
      method: "GET",
      headers: {
        reactkey: process.env.REACT_APP_AUTH_KEY,
        "Content-Type": "application/json",
      },
      // body: JSON.stringify(user),
    };
    const response = await fetch(url, config)
      .then((data) => data.json())
      .catch((err) => err);
    if (response.success) {
      let fixtures = JSON.parse(localStorage.getItem("fixtures"));
      if (!fixtures) {
        let obj = {};
        response.data.forEach((item) => {
          obj[item.id] = item;
        });
        localStorage.setItem("fixtures", JSON.stringify(obj));
      } else {
        let obj = fixtures;
        response.data.forEach((item) => {
          obj[item.id] = item;
        });
        localStorage.setItem("fixtures", JSON.stringify(obj));
      }
      setFixtures(response.data);
      setCompetition(response.competition);
    } else {
    }
    setLoading(false);
  };

  useEffect(() => {
    getFixtures();
  }, []);

  useEffect(() => {
    if (!localStorage.getItem("pairDetails")) {
      localStorage.setItem("pairDetails", JSON.stringify(pairDetails));
    }
    if (JSON.stringify(pairDetails) != localStorage.getItem("pairDetails")) {
      const details = JSON.parse(localStorage.getItem("pairDetails"));
      setPairDetails(details);
    }
  }, []);

  useEffect(() => {
    if (pairDetails?.max_date) {
      let new_fixtures = fixtures.filter((fixture) => {
        return fixture.date <= pairDetails.max_date;
      });
      setFilteredFixtures(new_fixtures);
    }
  }, [pairDetails, fixtures]);

  const tour = useSelector((state) => state.tour.currentTour);
  const [stepIndex, setStepIndex] = useState(0);
  const [run, setRun] = useState(false);

  const handleJoyrideCallback = (data) => {
    const { action, index, origin, status, type } = data;

    // if (action === ACTIONS.CLOSE || action == 'stop') {
    //   // do something
    //   dispatch(setTourMode(false))
    //   dispatch(setCurrentTour(""))
    // }

    // if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
    //   // Update state to advance the tour
    //   setStepIndex(index + (action === ACTIONS.PREV ? -1 : 1));
    // } else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
    //   // You need to set our running state to false, so we can restart if we click start again.
    //   // setRun(false);
    // }

    // console.groupCollapsed(type);
    //eslint-disable-line no-console
    // console.groupEnd();
  };

  useEffect(() => {
    if (tour == "fixtures") {
      setTimeout(() => {
        setRun(true);
        
      }, 1000);
    } else {
      setRun(false);
    }
  }, [tour]);

  return (
    <>
      <div className="d-none d-lg-block">
        <Regions>
          {loading ? (
            <Loading />
          ) : (
            <>
              <ColumnHeader
                name={competition.competition_name}
                image={competition.competition_emblem}
              />
              <div style={{ height: "80dvh", overflow: "auto" }} className="" data-tut={`${!isMobile && 'select-fixture-step-0'}`} >
                {pairDetails &&
                pairDetails.pairType === "league" &&
                pairDetails.max_date ? (
                  filteredFixtures.length > 0 ? (
                    filteredFixtures.slice(0, 15).map((fixture, i) => {
                      return (
                        <div key={i} className="mb-1">
                          <Fixture fixture={fixture} />
                        </div>
                      );
                    })
                  ) : (
                    <NoFixture />
                  )
                ) : fixtures.length > 0 ? (
                  fixtures.slice(0, 15).map((fixture, i) => {
                    return (
                      <div
                        key={i}
                        className="mb-1"
                        // data-tut={`select-fixture-step-${i}`}
                      >
                        <Fixture fixture={fixture} />
                      </div>
                    );
                  })
                ) : (
                  <NoFixture />
                )}
              </div>
            </>
          )}
        </Regions>

      </div>
      <div className="d-block d-lg-none"> 
        <LayoutHeader>
          {loading ? (
            <Loading />
          ) : (
            <>
              <Joyride
                steps={fixturesSteps}
                run={run && tourMode}
                autoStart={true}
                stepIndex={stepIndex}
                continuous={true}
                showSkipButton={true}
                allowClicksThruHole={true}
                disableOverlay={false}
                disableOverlayClose={false}
                spotlightClicks={true}
                callback={handleJoyrideCallback}
                hideCloseButton={true}
              ></Joyride>
              <div
                className="justify-center justify-content-between px-3 medium"
                style={{ height: "8%" }}
                
              >
                <div className="between-row justify-content-start">
                  <Back />
                  <ColumnHeader
                    name={competition.competition_name}
                    image={competition.competition_emblem}
                  />
                </div>
                <ViewSelections />
              </div>
              <div style={{ height: "auto",maxHeight:'92%', overflow: "auto" }} className="" data-tut={`${isMobile && 'select-fixture-step-0'}`}>
                {pairDetails &&
                pairDetails.pairType === "league" &&
                pairDetails.max_date ? (
                  filteredFixtures.length > 0 ? (
                    filteredFixtures.map((fixture, i) => {
                      return (
                        <div
                          key={i}
                          className="mb-1"
                          
                        >
                          <Fixture fixture={fixture} />
                        </div>
                      );
                    })
                  ) : (
                    <NoFixture />
                  )
                ) : fixtures.length > 0 ? (
                  fixtures.map((fixture, i) => {
                    return (
                      <div
                        key={i}
                        className="mb-1"
                        data-tut={`select-fixture-step-${i}`}
                      >
                        <Fixture fixture={fixture} />
                      </div>
                    );
                  })
                ) : (
                  <NoFixture />
                )}
              </div>
            </>
          )}
        </LayoutHeader>

      </div>
      
      
    </>
  );
}

export function NoFixture() {
  return <div className="h-100 justify-center sans">No Fixture Available</div>;
}
