import { useEffect, useState } from "react";
import { LayoutHeader } from "../components/layout";
import "../index.css";
import "../styles/select.css";
import "../styles/ticket.css";

import { useNavigate, useParams } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { useDispatch, useSelector } from "react-redux";
import { Loading } from "../App";
import { setCurrentRegion, setCompetitions } from "../redux/slices/selectSlice";
import Regions from "../pages/select";
import { ColumnHeader } from "../pages/select";
import { Back } from "../pages/select";
import { ViewSelections } from "../pages/select";
import { setTourMode } from "../redux/slices/tourSlice";
import competitonsSteps  from "../components/tour/competitionsTour";
import { setCurrentTour, setAllowButtonClick } from "../redux/slices/tourSlice";

import Joyride, {
  ACTIONS,
  EVENTS,
  ORIGIN,
  STATUS,
  CallBackProps,
} from "react-joyride";

function Competition(props) {
  const { name, image } = props;

  
  return (
    <div
      className="around-col align-items-center rounded-3 p-2"
      style={{ height: "170px", pointerEvents: "none" }}
    >
      <img src={image} width="70px" height="70px" />
      <div className=" text-center medium sans seven">{name}</div>
    </div>
  );
}

export function NoCompetition() {
  return (
    <div className="h-100 justify-center sans">No Competition Available</div>
  );
}

export function Competitions() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const current_country = useSelector((state) => state.select.currentRegion);
  const [loading, setLoading] = useState(false);
  const competitions = useSelector((state) => state.select.competitions);
  const tourMode = useSelector((state) => state.tour.tourMode);
  
  let { countryId } = useParams();
  const [country, setCountry] = useState({
    name: "",
    logo: "",
  });
  const isMobile = useMediaQuery({ maxWidth: 768 });

  useEffect(() => {
    setLoading(true);
    const getCompetitions = async (countryId) => {
      const url = `${process.env.REACT_APP_BASE_URL}/competitions/${countryId}`;

      const config = {
        method: "GET",
        headers: {
          reactkey: process.env.REACT_APP_AUTH_KEY,
          "Content-Type": "application/json",
        },
      };

      const response = await fetch(url, config)
        .then((data) => data.json())
        .catch((err) => err);

      let data = response.data;
      let country = response.country;

      if (response.success) {
        dispatch(setCompetitions(data));
        setCountry({
          name: country.country,
          logo: country.country_flag,
        });
      }
      setLoading(false);
    };
    getCompetitions(countryId);
  }, [current_country]);

  function handleClick(i) {
    navigate(`/select/fixtures/${i}`);
    closeTut("fixtures");
  }

  
  const [currentStep, setCurrentStep] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  const [run, setRun] = useState(false);
  const [stepIndex, setStepIndex] = useState(0);
  const [diableOverlay, setdiableOverlay] = useState(true);
  const currentTour = useSelector((state) => state.tour.currentTour);

  

  useEffect(() => {
    console.log(run,tourMode,currentTour)

  })

  useEffect(() => {
    console.log(currentTour)
    
    if (currentTour == "competitions") {
      setTimeout(() => {
        setRun(true);
        
        // document.querySelector('.modal-backdrop.show').style.display= "none"
      }, 500);
    } else {
      setRun(false);
    }

  }, [currentTour]);

  const closeTut = (tut) => {
    let tutorial = tut ? tut : "none";
    dispatch(setCurrentTour(tutorial));
  };

  const handleJoyrideCallback = (data) => {
    const { action, index, origin, status, type } = data;
    // console.log(`competitons tour action ${action} ${status}`);

    // if (action === ACTIONS.CLOSE || action == 'stop') {
    //   // do something
    //   dispatch(setTourMode(false))
    //   closeTut("");
    // }

    // if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
    //   // Update state to advance the tour
    //   setStepIndex(index + (action === ACTIONS.PREV ? -1 : 1));
    // } else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
    //   // You need to set our running state to false, so we can restart if we click start again.
    //   // setRun(false);
    // }

    // console.groupCollapsed(type);
    
    //eslint-disable-line no-console
    // console.groupEnd();
  };

  return (
    <>
      <div className="d-none d-lg-block">
        <Regions>
          <ColumnHeader
            name={
              current_country.name !== "" ? current_country.name : country.name
            }
            image={
              current_country.logo !== "" ? current_country.logo : country.logo
            }
          />
          {loading ? (
            <Loading />
          ) : (
            <>
              {competitions.length > 0 ? (
                <div
                  className="p-3 grid-three"
                  style={{ maxHeight: "79dvh", overflow: "auto" }}
                  data-tut={`${!isMobile && "select-comp-step-0" }`}
                >
                  {competitions?.map((competition, index) => (
                    <div
                      key={index}
                      className="mb-3 cursor mx-2 competition"
                      onClick={() => handleClick(competition.id)}
                    >
                      <Competition
                        name={competition.competition_name}
                        image={competition.competition_emblem}
                        indx={index}
                      />
                    </div>
                  ))}
                </div>
              ) : (
                <NoCompetition />
              )}
            </>
          )}
        </Regions>

      </div>
      <div className="d-lg-none">
        <LayoutHeader>
          <div className="justify-center justify-content-between px-3" >
            <div className="between-row justify-content-start">
              <Back />
              <ColumnHeader
                name={
                  current_country.name !== ""
                    ? current_country.name
                    : country.name
                }
                image={
                  current_country.logo !== ""
                    ? current_country.logo
                    : country.logo
                }
              />
            </div>
            <ViewSelections />
          </div>

          {loading ? (
            <Loading />
          ) : (
            <>
              <Joyride
                steps={competitonsSteps}
                run={run && tourMode}
                autoStart={true}
                stepIndex={stepIndex}
                continuous={true}
                showSkipButton={false}
                allowClicksThruHole={true}
                disableOverlay={false}
                disableOverlayClose={false}
                spotlightClicks={true}
                callback={handleJoyrideCallback}
                hideCloseButton={true}
              ></Joyride>

              {competitions.length > 0 ? (
                <div className="p-3 grid-three" style={{ overflow: "auto" }} data-tut={`${isMobile && "select-comp-step-0" }`}>
                  {competitions?.map((competition, index) => {
                    return (
                      <div
                        key={index}
                        className="mb-3 cursor mx-2 competition"
                        onClick={() => handleClick(competition.id)}
                        
                        id={JSON.stringify(competition)}
                      >
                        <Competition
                          name={competition.competition_name}
                          image={competition.competition_emblem}
                        />
                      </div>
                    );
                  })}
                </div>
              ) : (
                <NoCompetition />
              )}
            </>
          )}
        </LayoutHeader>

      </div>
      
    </>
  );
}
