import "../index.css";
import "../styles/layout.css";
import def from "../images/default.png";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import ovo from "../images/1v1.webp";
import league from "../images/league.webp";
import pool from "../images/pool.webp";
import pinish from "../images/pinish.webp";
import handshake from "../images/handshake.webp";
import { click } from "@testing-library/user-event/dist/click";
import { useDispatch, useSelector } from "react-redux";
import { LoginButton } from "./login";
import { getAppData } from "../redux/slices/generalSlice";
import { Loading } from "../App";
import { setPairDetails } from "../redux/slices/ticketSlice";
import { Wallet } from "../pages/profile";
import { useMediaQuery } from "react-responsive";
import streak_icon from "../images/fire.png";
import Sidebar from "./Sidebar";
import OvoModal from "./OvoModal";

import { Tour, useTour } from "@reactour/tour";


export default function Layout(props) {
  // changing height from 80dvh to 100%
  const { children, sides = 3, up = 5, height = 100 } = props;
  const loading = useSelector((state) => state.general.loading);
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const { onTourChange } = useTour();
  const tour = useSelector((state) => state.tour.currentTour);
  const [currentStep, setCurrentStep] = useState(0);

  const [isOpen, setIsOpen] = useState(false);

  // useEffect(() => {
  

  //   if (tour == "intro") {
  //     setTimeout(() => {
  //       setIsOpen(true);
  //     }, 1000);
  //   } else {
  //     setIsOpen(false);
  //   }
  // }, [tour]);

  

  

  // const help_modal = useRef();

  // useEffect(() => {
  //     if (localStorage.getItem('show-tutorial')) {
  //         if (localStorage.getItem('show-tutorial') === 'false') {
  //             help_modal.current.click();
  //             localStorage.setItem('show-tutorial', 'true');
  //         }
  //     }

  // }, [])

  return (
    <>
      <div className="container-fluid p-0">
        <div className="layout">
          <Sidebar />

          {loading ? (
            <Loading height="100dvh" />
          ) : (
            <div className="children">
              <Navbar mobile={true} size="25" useLogo={isMobile} />

              <div className="layout-child-div">{children}</div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export function Navbar(props) {
  const { useLogo, size = "36", variant = "dark" } = props;
  const authenticated = useSelector((state) => state.auth.authenticated);
  const money_balance = useSelector(
    (state) => state.general.profile?.money_balance
  );
  const user = useSelector((state) => state.general.profile);
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const loading = useSelector((state) => state.general.loading);

  const navigate = useNavigate();
  return (
    <div className="mob_nav">
      <Wallet />
      <div className="between-row navbar">
        <div className="between-row cursor">
          {!isMobile && authenticated && user ? (
            <div onClick={() => navigate("/profile")} className="between-row">
              <img
                src={user.profile_pic}
                width="50px"
                height="50px"
                className="rounded-circle"
              />
              <h4 className="ms-1 my-0">
                <b>Hello, {user.username}</b>
              </h4>
            </div>
          ) : (
            useLogo && (
              <Logo
                clickable={true}
                height={size}
                width="56"
                variant={variant}
              />
            )
          )}
        </div>

        <div className="align-row-center">
          {!loading &&
            (authenticated && user ? (
              <>
                <span className="me-2 align-row-center seven">
                  ₦{money_balance}
                </span>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="cursor"
                  data-bs-toggle="modal"
                  data-bs-target="#walletModal"
                >
                  <path
                    d="M11.2954 9.03279V5.1641H9.36104V9.03279H5.49235V10.9671H9.36104V14.8358H11.2954V10.9671H15.1641V9.03279H11.2954ZM10.3282 19.6717C7.76311 19.6717 5.30307 18.6527 3.48927 16.8389C1.67548 15.0251 0.656494 12.5651 0.656494 9.99996C0.656494 7.43486 1.67548 4.97482 3.48927 3.16103C5.30307 1.34723 7.76311 0.328247 10.3282 0.328247C12.8933 0.328247 15.3533 1.34723 17.1671 3.16103C18.9809 4.97482 19.9999 7.43486 19.9999 9.99996C19.9999 12.5651 18.9809 15.0251 17.1671 16.8389C15.3533 18.6527 12.8933 19.6717 10.3282 19.6717Z"
                    fill="#00D755"
                  />
                </svg>
              </>
            ) : (
              <LoginButton />
            ))}
        </div>
      </div>
    </div>
  );
}

export function LayoutHeader({ children }) {
  const authenticated = useSelector(
    (state) => state.auth.authenticated && true
  );
  const isMobile = useMediaQuery({ maxWidth: 768 });

  return (
    <div className="pt-md-0">
      <Navbar useLogo={!authenticated || isMobile} size="25" />
      {/* <div className="mt-md-0 mt-0 reg_dem overflow-y-auto"> */}
        <div className="main-children-cont">{children}</div>
      {/* </div> */}
    </div>
  );
}

export function Logo(props) {
  const { clickable, height, width, variant = "dark", sidebar } = props;
  const navigate = useNavigate();

  return (
    <div
      className={`${clickable && "cursor"} ${sidebar && "logo-sidebar"}`}
      onClick={clickable ? () => navigate("/") : null}
    >
      {variant === "dark" ? (
        <svg
          width={width}
          height={height}
          viewBox="0 0 56 36"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M53.2452 11.6178L55.2279 0.395508L27.9176 10.9776L0.594238 0.395508L2.58999 11.6178L19.2913 18.5549L4.88772 24.6559L6.83095 35.6038L27.9176 27.3603L48.9911 35.6038L50.9344 24.6559L36.544 18.5549L53.2452 11.6178Z"
            fill="black"
          />
        </svg>
      ) : (
        <svg
          width="40"
          height="40"
          viewBox="0 0 32 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M29.9083 6.37477L31.0345 0L15.521 6.01113L0 0L1.13368 6.37477L10.6208 10.3154L2.4389 13.7811L3.54275 20L15.521 15.3173L27.4917 20L28.5956 13.7811L20.4212 10.3154L29.9083 6.37477Z"
            fill="white"
          />
        </svg>
      )}
    </div>
  );
}

export function TransparentHeader({ children }) {
  let white_logo = (
    <svg
      width="32"
      height="20"
      viewBox="0 0 32 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M29.9083 6.37477L31.0345 0L15.521 6.01113L0 0L1.13368 6.37477L10.6208 10.3154L2.4389 13.7811L3.54275 20L15.521 15.3173L27.4917 20L28.5956 13.7811L20.4212 10.3154L29.9083 6.37477Z"
        fill="white"
      />
    </svg>
  );
  const authenticated = useSelector(
    (state) => state.auth.authenticated && true
  );
}
